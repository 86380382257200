import Utility from '../../../shared/utility.js';
import EncryptUtility from './encrypt-utility.js';
import AddFavourite from "./addFav.js";
import breadcrumbComp from '../../common/breadcrumb-comp';
import PrinterDetails from '../../common/printer-details';
export default {
  data() {
    return {
      refresh: true,
      backSlash: true,
      actionList: [],
      mainPage: "",
      subPage: "",
      pageUrl: "",
      favouriteList: [],
      showAdd: false,
      userId: EncryptUtility.localStorageDecrypt('userID'),
      labelPrinter: EncryptUtility.localStorageDecrypt('primaryPrinterType') == null ||
        EncryptUtility.localStorageDecrypt('primaryPrinterType') == undefined ? true : false,
      printerType: '',
      unitIdentifyValid: false,
      unitIdentifier: '',
      primaryLabelSize: '',
      primaryPrinterType: '',
      validatedUnitIdent: false,
      isFormValid: false,
      pass: 0,
      binLPN: '',
      wareKey: 0,
      projKey: 0,
      searchPartDetails: false,
      searchOtherDetails: false,
      partDetails: [],
      projectData: [],
      newPartNum: '',
      newPartFormValid: false,
      selectedProject: '',
      newPartOtherFormValid: false,
      projectDetails: [],
      binSearched: false,
      selectedWarehouse: '',
      projectSelected: false,
      wareHouseData: [],
      wareHouseSelected: false,
      quantity: 0,
      searchBin: false,
      warehouseChanged: true,
      binLPNNum: '',
      newPartNumData: [],
      esnNum: '',
      mainKeyVal: false,
      esnVal: false,
      bcnVal: false,
      serialNoVal: false,
      iccidNoVal: false,
      bcnNum: '',
      serialNoNum: '',
      iccidNum: '',
      primaryName: EncryptUtility.localStorageDecrypt('primaryName'),
      max50Rule: [
        (v) => !!v || 'Field is required',
        (v) => (v || '').length <= 50 || "Max Length of 50 character"
      ],
      max36Rule: [
        (v) => !!v || 'Field is required',
        (v) => (v || '').length <= 36 || "Max Length of 36 character"
      ],
      max5Rule: [
        (v) => !!v || 'Field is required',
        (v) => (v || '').length <= 5 || "Max Length of 5 character"
      ],
    };
  },
  async created() {
    if (EncryptUtility.localStorageDecrypt('primaryPrinterType') !== null && EncryptUtility.localStorageDecrypt('primaryPrinterType') !== undefined) {
      this.printerType = EncryptUtility.localStorageDecrypt('primaryPrinterType');
    }
    this.route = this.$route.path.replace('/', '');
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  mounted() {
  },
  methods: {
    //to check if its a number of not
    isNumber(event) {
      let value = Utility.isNumber(event)
      return value
    },
    //To add or remove Favourite
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route)
    },
    //Reset Function
    resetFunction() {
      this.unitIdentifyValid = false;
      this.unitIdentifier = '';
      this.validatedUnitIdent = false;
      this.isFormValid = false;
      this.pass = 0;
      this.binLPN = '';
      this.wareKey = 0;
      this.projKey = 0;
      this.searchPartDetails = false;
      this.searchOtherDetails = false;
      this.partDetails = [];
      this.projectData = [];
      this.newPartNum = '';
      this.newPartFormValid = false;
      this.selectedProject = '';
      this.newPartOtherFormValid = false;
      this.projectDetails = [];
      this.projectSelected = false;
      this.binSearched = false;
      this.selectedWarehouse = '';
      this.wareHouseData = [];
      this.wareHouseSelected = false;
      this.quantity = 0;
      this.searchBin = false;
      this.warehouseChanged = true;
      this.binLPNNum = '';
      this.newPartNumData = [];
      this.mainKeyVal = false;
      this.esnVal = false;
      this.bcnVal = false;
      this.serialNoVal = false;
      this.bcnNum = '';
      this.serialNoNum = '';
      this.iccidNum = '';
      this.iccidNoVal = false;
      this.$refs.UnitIdentifierForm.resetValidation();
      this.$refs.NewPartNum.resetValidation();
      this.$refs.NewPartNumOther.resetValidation();
    },
    //Search based on entered unit identifier
    onSearchClick() {
      if (!this.$refs.UnitIdentifierForm.validate()) return false;
      this.pass = 1;
      if (EncryptUtility.localStorageDecrypt('primaryLabelSize') !== null && EncryptUtility.localStorageDecrypt('primaryLabelSize') !== undefined) {
        this.primaryLabelSize = EncryptUtility.localStorageDecrypt('primaryLabelSize');
      }
      if (EncryptUtility.localStorageDecrypt('primaryPrinterType') !== null && EncryptUtility.localStorageDecrypt('primaryPrinterType') !== undefined) {
        this.primaryPrinterType = EncryptUtility.localStorageDecrypt('primaryPrinterType');
      }
      let LoaderDialog = {
        visible: true,
        title: 'Please Wait...',
      };
      this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
      let searchUnitIdentifier = {
        pass: parseInt(this.pass),
        unit: this.unitIdentifier,
        bin_lpn: this.binLPN,
        ware_key: this.wareKey,
        qty: parseInt(this.quantity),
        new_part: this.newPartNum,
        UserId: parseInt(this.userId),
        proj_key: this.projKey,
        lbl_size: this.primaryLabelSize,
        printer_type: this.primaryPrinterType,
        bcn: '',
        serialno: '',
        esn: '',
        iccid: '',
        main_key: 0,
      };
      this.axios
        .post('/ut/partnumber_change', searchUnitIdentifier)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let responsedata = [];
            responsedata = JSON.parse(response.data.body.message);
            this.isFormValid = true;
            this.validatedUnitIdent = true;
            if (responsedata.bc == '1') {
              this.partDetails = responsedata;
              this.searchPartDetails = true;
            } else {
              this.searchOtherDetails = true;
              this.projectData = responsedata.Projects;
              if (this.projectData.length == 1) {
                this.selectedProject = this.projectData[0].proj_key;
                this.projectChange();
              }
            }
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
            let Alert = {
              type: 'error',
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit('ConfigModule/Alert', Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          let Alert = {
            type: 'error',
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit('ConfigModule/Alert', Alert);
          this.errorMessage = error.message;
        });
    },
    //Project Selected
    projectChange() {
      this.pass = 1;
      if (EncryptUtility.localStorageDecrypt('primaryLabelSize') !== null && EncryptUtility.localStorageDecrypt('primaryLabelSize') !== undefined) {
        this.primaryLabelSize = EncryptUtility.localStorageDecrypt('primaryLabelSize');
      }
      if (EncryptUtility.localStorageDecrypt('primaryPrinterType') !== null && EncryptUtility.localStorageDecrypt('primaryPrinterType') !== undefined) {
        this.primaryPrinterType = EncryptUtility.localStorageDecrypt('primaryPrinterType');
      }
      let LoaderDialog = {
        visible: true,
        title: 'Please Wait...',
      };
      this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
      let searchUnitIdentifier = {
        pass: parseInt(this.pass),
        unit: this.unitIdentifier,
        bin_lpn: this.binLPN,
        ware_key: this.wareKey,
        qty: parseInt(this.quantity),
        new_part: this.newPartNum,
        UserId: parseInt(this.userId),
        proj_key: parseInt(this.selectedProject),
        lbl_size: this.primaryLabelSize,
        printer_type: this.primaryPrinterType,
        bcn: '',
        serialno: '',
        esn: '',
        iccid: '',
        main_key: 0,
      };
      this.axios
        .post('/ut/partnumber_change', searchUnitIdentifier)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let responsedata = [];
            responsedata = JSON.parse(response.data.body.message);
            this.projectSelected = true;
            this.projectDetails = responsedata;
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
            let Alert = {
              type: 'error',
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit('ConfigModule/Alert', Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          let Alert = {
            type: 'error',
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit('ConfigModule/Alert', Alert);
          this.errorMessage = error.message;
        });
    },
    //Bin or LPN Search
    binSearch() {
      this.pass = 2;
      if (EncryptUtility.localStorageDecrypt('primaryLabelSize') !== null && EncryptUtility.localStorageDecrypt('primaryLabelSize') !== undefined) {
        this.primaryLabelSize = EncryptUtility.localStorageDecrypt('primaryLabelSize');
      }
      if (EncryptUtility.localStorageDecrypt('primaryPrinterType') !== null && EncryptUtility.localStorageDecrypt('primaryPrinterType') !== undefined) {
        this.primaryPrinterType = EncryptUtility.localStorageDecrypt('primaryPrinterType');
      }
      let LoaderDialog = {
        visible: true,
        title: 'Please Wait...',
      };
      this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
      let searchUnitIdentifier = {
        pass: parseInt(this.pass),
        unit: this.unitIdentifier,
        bin_lpn: this.binLPNNum,
        ware_key: this.wareKey,
        qty: parseInt(this.quantity),
        new_part: this.newPartNum,
        UserId: parseInt(this.userId),
        proj_key: parseInt(this.selectedProject),
        lbl_size: this.primaryLabelSize,
        printer_type: this.primaryPrinterType,
        bcn: '',
        serialno: '',
        esn: '',
        iccid: '',
        main_key: 0,
      };
      this.axios
        .post('/ut/partnumber_change', searchUnitIdentifier)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let responsedata = [];
            responsedata = JSON.parse(response.data.body.message);
            this.binSearched = true;
            this.searchBin = true;
            this.wareHouseData = responsedata.Ware;
            if (this.wareHouseData.length == 1) {
              this.selectedWarehouse = this.wareHouseData[0].ware_id;
              this.wareHouseChange();
            }
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
            let Alert = {
              type: 'error',
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit('ConfigModule/Alert', Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          let Alert = {
            type: 'error',
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit('ConfigModule/Alert', Alert);
          this.errorMessage = error.message;
        });
    },
    //New Part Number Submit for MainKey Data
    newPartNumSubmit() {
      this.pass = 3;
      this.selectedProject = '0';
      this.selectedWarehouse = '0';
      if (EncryptUtility.localStorageDecrypt('primaryLabelSize') !== null && EncryptUtility.localStorageDecrypt('primaryLabelSize') !== undefined) {
        this.primaryLabelSize = EncryptUtility.localStorageDecrypt('primaryLabelSize');
      }
      if (EncryptUtility.localStorageDecrypt('primaryPrinterType') !== null && EncryptUtility.localStorageDecrypt('primaryPrinterType') !== undefined) {
        this.primaryPrinterType = EncryptUtility.localStorageDecrypt('primaryPrinterType');
      }
      let LoaderDialog = {
        visible: true,
        title: 'Please Wait...',
      };
      this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
      let newPartNumSubmit = {
        pass: parseInt(this.pass),
        unit: this.unitIdentifier,
        bin_lpn: this.binLPNNum,
        ware_key: this.selectedWarehouse,
        qty: parseInt(this.quantity),
        new_part: this.newPartNum,
        UserId: parseInt(this.userId),
        proj_key: parseInt(this.selectedProject),
        lbl_size: this.primaryLabelSize,
        printer_type: this.primaryPrinterType,
        bcn: '',
        serialno: '',
        esn: '',
        iccid: '',
        main_key: 0,
      };
      this.axios
        .post('/ut/partnumber_change', newPartNumSubmit)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let responsedata = [];
            responsedata = JSON.parse(response.data.body.message);
            if (responsedata.Info[0].label != null && responsedata.Info[0].label != undefined) {
              PrinterDetails.printLabelData(responsedata.Info[0].label, this.primaryName);
              this.resetFunction();
            } else {
              this.newPartNumData = responsedata.Info[0];
              if (this.newPartNumData.prompt_bcn == true) this.bcnVal = true;
              if (this.newPartNumData.prompt_esn == true) this.esnVal = true;
              if (this.newPartNumData.prompt_serialno == true) this.serialNoVal = true;
              if (this.newPartNumData.prompt_iccid == true) this.iccidNoVal = true;
              this.mainKeyVal = true;
            }
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
            let Alert = {
              type: 'error',
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit('ConfigModule/Alert', Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          let Alert = {
            type: 'error',
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit('ConfigModule/Alert', Alert);
          this.errorMessage = error.message;
        });
    },
    //Warehouse selected
    wareHouseChange() {
      this.wareHouseSelected = true;
    },
    //New Part Number Submit
    newPartNumOtherSubmit() {
      this.pass = 3;
      if (EncryptUtility.localStorageDecrypt('primaryLabelSize') !== null && EncryptUtility.localStorageDecrypt('primaryLabelSize') !== undefined) {
        this.primaryLabelSize = EncryptUtility.localStorageDecrypt('primaryLabelSize');
      }
      if (EncryptUtility.localStorageDecrypt('primaryPrinterType') !== null && EncryptUtility.localStorageDecrypt('primaryPrinterType') !== undefined) {
        this.primaryPrinterType = EncryptUtility.localStorageDecrypt('primaryPrinterType');
      }
      let LoaderDialog = {
        visible: true,
        title: 'Please Wait...',
      };
      this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
      let newPartNumOtherSubmit = {
        pass: parseInt(this.pass),
        unit: this.unitIdentifier,
        bin_lpn: this.binLPNNum,
        ware_key: this.selectedWarehouse,
        qty: parseInt(this.quantity),
        new_part: this.newPartNum,
        UserId: parseInt(this.userId),
        proj_key: parseInt(this.selectedProject),
        lbl_size: this.primaryLabelSize,
        printer_type: this.primaryPrinterType,
        bcn: '',
        serialno: '',
        esn: '',
        iccid: '',
        main_key: 0,
      };
      this.axios
        .post('/ut/partnumber_change', newPartNumOtherSubmit)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let responsedata = [];
            responsedata = JSON.parse(response.data.body.message);
            PrinterDetails.printLabelData(responsedata.Info[0].label, this.primaryName);
            this.resetFunction();
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
            let Alert = {
              type: 'error',
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit('ConfigModule/Alert', Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          let Alert = {
            type: 'error',
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit('ConfigModule/Alert', Alert);
          this.errorMessage = error.message;
        });
    },
    //New Part Number Submit
    newPartNumMainSubmit() {
      this.pass = 4;
      this.selectedProject = '0';
      this.selectedWarehouse = '0';
      if (EncryptUtility.localStorageDecrypt('primaryLabelSize') !== null && EncryptUtility.localStorageDecrypt('primaryLabelSize') !== undefined) {
        this.primaryLabelSize = EncryptUtility.localStorageDecrypt('primaryLabelSize');
      }
      if (EncryptUtility.localStorageDecrypt('primaryPrinterType') !== null && EncryptUtility.localStorageDecrypt('primaryPrinterType') !== undefined) {
        this.primaryPrinterType = EncryptUtility.localStorageDecrypt('primaryPrinterType');
      }
      let LoaderDialog = {
        visible: true,
        title: 'Please Wait...',
      };
      this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
      let newPartNumMainSubmit = {
        pass: parseInt(this.pass),
        unit: this.unitIdentifier,
        bin_lpn: this.binLPNNum,
        ware_key: this.selectedWarehouse,
        qty: parseInt(this.quantity),
        new_part: this.newPartNum,
        UserId: parseInt(this.userId),
        proj_key: parseInt(this.selectedProject),
        lbl_size: this.primaryLabelSize,
        printer_type: this.primaryPrinterType,
        bcn: this.bcnNum,
        serialno: this.serialNoNum,
        esn: this.esnNum,
        iccid: this.iccidNum,
        main_key: parseInt(this.newPartNumData.main_key),
      };
      this.axios
        .post('/ut/partnumber_change', newPartNumMainSubmit)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let responsedata = [];
            responsedata = JSON.parse(response.data.body.message);
            PrinterDetails.printLabelData(responsedata.Info[0].label, this.primaryName);
            this.resetFunction();
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
            let Alert = {
              type: 'error',
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit('ConfigModule/Alert', Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          let Alert = {
            type: 'error',
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit('ConfigModule/Alert', Alert);
          this.errorMessage = error.message;
        });
    },
  },
  components: {
    breadcrumbComp,
    PrinterDetails,
  },
};